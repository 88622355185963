/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnCommonModule } from '@mn/common';
import { MnRouteModule } from '@mn/route';
import { MnHelpModule } from '@mn/help';


//
import { CtCeHome } from './CtCeHome';

//Note for BB: new materail design modules should be added to CtWorkflowModule.ts and not here
import {
    MatButtonModule,
} from '@angular/material';

let material_modules = [
    MatButtonModule,
];

let directives = [];
let components = [CtCeHome];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnCommonModule,
        MnRouteModule,
        MnHelpModule,
        ...material_modules,
    ],
    exports: components.concat(directives),
    declarations: components.concat(directives),
    entryComponents: components
})
export class CtCeAppModule {
    constructor() {
        console.log("constructor CtCeAppModule")
    }
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CtCeAppModule,
            providers: []
        };
    }
}

export class ct_cetoxgps {
    static configure():any {
        return [
            CtCeAppModule.forRoot()
        ];
    }
}