/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,HostListener} from '@angular/core';

// uirouter
import {StateService} from '@uirouter/angular';

// mn
import {MnBackend} from "@mn/core";

@Component({
    selector: 'ct-home',
    templateUrl: './CtCeHome.html',
    styleUrls: ['./CtCeHome.css']
})
export class CtCeHome {

    constructor(private mStateService:StateService, private mBackend:MnBackend) {}

    onDataManager() {
        this.mStateService.go('app.datamanager');
    }

    onDatabaseSearch() {
        this.mBackend.post('/workflows/workflow/',{context:'chemtunes'})
            .map(res => res.json())
            .subscribe(wf => {
                console.log(wf);
                this.mStateService.go('app.workflow',{wid: wf.id, sid: wf.active_state, aid: 'SEARCH_DATABASE'});
        });
    }
    onReadAcross() {

    }



}
